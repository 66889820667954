<template>
  <div class="grade-detail">
    <b-container class="grade-detail-container">
      <b-row align-h="between">
        <b-col lg="8" md="12">
          <h2>{{ assetAccountDetail.name }}</h2>
          <b-card class="total-card">
            <b-row cols="3" no-gutters>
              <b-col>
                <div class="total-item">
                  <h3>{{ assetDataDetail.net_value | toFixed }}</h3>
                  <div>NV</div>
                </div>
              </b-col>
              <b-col>
                <div class="total-item">
                  <h3>{{ assetDataDetail.max_drawdown | toFixed }}%</h3>
                  <div>最大回撤</div>
                </div>
              </b-col>
              <b-col>
                <div class="total-item">
                  <h3 class="year-return-ratio">{{ assetDataDetail.annualized_return_ratio | toFixed }}%</h3>
                  <div>年化收益率</div>
                </div>
              </b-col>
            </b-row>
          </b-card>
          <div class="chart-container mb-4">
            <div class="charts-header">
              <div class="charts-legend">
                <div class="charts-legend-item" :class="{hidden: legend.hidden}" v-for="legend in legends" :key="legend.name" @click="toggleLegend(legend)">
                  <div class="legend-symbol" :style="{backgroundColor: legend.color}"></div>
                  {{ legend.name }}
                </div>
              </div>
              <div class="charts-granularity">
                <div class="charts-granularity-item" :class="{active: currentGranularity===g.value}" @click="selectGranularity(g)" v-for="g in granularityList" :key="g.value">{{ g.label }}</div>
              </div>
            </div>
            <charts ref="charts" :option="chartOption" width="100%" height="500px"/>
          </div>
        </b-col>
        <b-col lg="4" md="12">
          <b-button type="light" variant="dark" pill block @click="showContactDialog">联系我们</b-button>
          <b-list-group class="detail-board mb-4">
            <b-list-group-item class="detail-board-item">
              <div class="label">更新时间</div>
              <div class="text">{{ assetAccountDetail.update_time | toDateFmt }}</div>
            </b-list-group-item>
            <b-list-group-item class="detail-board-item">
              <div class="label">管理规模</div>
              <div class="text">{{ assetAccountDetail.initial_capital | toFixed }} USDT</div>
            </b-list-group-item>
            <b-list-group-item class="detail-board-item">
              <div class="label">现货</div>
              <div class="text">{{ assetAccountDetail.spot ? '是' : '否' }}</div>
            </b-list-group-item>
            <b-list-group-item class="detail-board-item">
              <div class="label">U本位合约</div>
              <div class="text">{{ assetAccountDetail.future ? '是' : '否' }}</div>
            </b-list-group-item>
            <b-list-group-item class="detail-board-item">
              <div class="label">币本位合约</div>
              <div class="text">{{ assetAccountDetail.delivery ? '是' : '否' }}</div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card title="交易记录">
            <div class="trade-query-box">
              <b-dropdown :text="tradeTypeTexts[tradeQuery.type] || '交易类型'">
                <b-dropdown-item @click="tradeQuery.type = ''">全部</b-dropdown-item>
                <template v-for="(v, k) in tradeTypeTexts">
                  <b-dropdown-item v-if="assetAccountDetail[k]" @click="tradeQuery.type = k" :key="k">{{ v }}</b-dropdown-item>
                </template>
              </b-dropdown>
              <b-dropdown :text="tradeSideTexts[tradeQuery.side] || '买卖方向'">
                <b-dropdown-item @click="tradeQuery.side = ''">全部</b-dropdown-item>
                <b-dropdown-item v-for="(v, k) in tradeSideTexts" @click="tradeQuery.side = k" :key="k">{{ v }}</b-dropdown-item>
              </b-dropdown>
              <b-dropdown :text="tradePositionSideTexts[tradeQuery.position_side] || '持仓方向'" v-if="tradeQuery.type !== 'spot'">
                <b-dropdown-item @click="tradeQuery.position_side = ''">全部</b-dropdown-item>
                <b-dropdown-item v-for="(v, k) in tradePositionSideTexts" @click="tradeQuery.position_side = k" :key="k">{{ v }}</b-dropdown-item>
                <b-dropdown-item v-if="tradeQuery.type === 'delivery'">单向持仓</b-dropdown-item>
              </b-dropdown>
              <b-dropdown :text="tradeQuery.symbol || '交易标的'">
                <b-dropdown-item @click="tradeQuery.symbol = ''">全部</b-dropdown-item>
                <b-dropdown-item @click="tradeQuery.symbol = `${symbol.symbol}USDT`" v-for="(symbol, index) in symbols" :key="index">{{ symbol.symbol }}</b-dropdown-item>
              </b-dropdown>
            </div>

            <b-table class="scalable position-relative" :responsive="true" sort-icon-left hover :items="trade.items" :fields="tradeFields" borderless>
              <template v-slot:cell(type)="{value}">
                {{ tradeTypeTexts[value] }}
              </template>
              <template v-slot:cell(side)="{value}">
                {{ tradeSideTexts[value] }}
              </template>
              <template v-slot:cell(position_side)="{value}">
                {{ tradePositionSideTexts[value] }}
              </template>
              <template v-slot:cell(time)="{value}">
                <span style="white-space: nowrap">{{ value | toDateFmt }}</span>
              </template>
            </b-table>
            <b-pagination v-model="trade.page" :per-page="trade.pageSize" :total-rows="trade.total" @input="fetchTradeList"/>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <Dialog v-if="contactDialogVisible" title="联系我们" @close="contactDialogVisible = false">
      <img style="width: 100%;" src="@/assets/contact-qrcode.png" alt=""/>
      <p style="margin: 1em auto;font-size: 16px;">请添加微信客服，备注【宽途官网】</p>
    </Dialog>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import Charts from '@/components/Charts'
import dayjs from 'dayjs'
import { toPercent, toRoundFixed } from '@/utils/math'
import Dialog from '@/components/Dialog'
import {
  getAssetAccountData,
  getAssetAccountDetail,
  getAssetAccountSeriesData,
  getAssetAccountSymbol,
  getAssetAccountTrade,
} from '@/api/assets'

const colors = ['#4421a5']

const gList = ['7d', '30d', '90d', 'all']

const gMap = {}

const decodeTimeAbbr = (abbr = '') => {
  return abbr.match(/[a-zA-Z]+|[0-9]+/g)
}

gList.forEach(g => {
  if (g === 'all') {
    gMap[g] = []
  } else {
    const [n, u] = decodeTimeAbbr(g)
    gMap[g] = [dayjs().add(-1 * (~~n - 1), u).startOf('day').unix(), dayjs().endOf('day').unix()]
  }
})

export default {
  name: 'AssetAccountDetail',
  components: {
    Dialog,
    Charts,
  },
  data () {
    return {
      contactDialogVisible: false,
      assetAccountDetail: {},
      assetDataDetail: {},
      legends: [
        {
          name: '净值',
          hidden: false,
          color: colors[0],
        },
      ],
      granularityList: gList.map(g => {
        return {
          label: g.toUpperCase(),
          value: g,
        }
      }),
      currentGranularity: 'all',
      startTime: '',
      endTime: '',
      chartOption: {
        xAxis: {
          type: 'category',
          boundaryGap: false,
        },
        yAxis: {
          type: 'value',
          min: 0.9,
          axisTick: {
            show: true,
          },
          axisLine: {
            show: true,
          },
          splitLine: {
            show: false,
          },
        },
        legend: {
          show: false,
          data: ['净值'],
        },
        tooltip: {
          trigger: 'axis',
        },
        series: [
          {
            name: '净值',
            data: [],
            type: 'line',
            symbol: 'circle',
            showSymbol: false,
            symbolSize: 10,
            lineStyle: {
              color: colors[0],
              width: 1,
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: colors[0],
              }, {
                offset: 1,
                color: 'rgba(58,77,233,0.1)',
              }]),
            },
          },
        ],
      },
      symbols: [],
      trade: {
        fields: [
          {
            key: 'trade_id',
            sortable: false,
            label: '交易ID',
          },
          {
            key: 'symbol',
            sortable: false,
            label: '交易对',
          },
          {
            key: 'side',
            sortable: false,
            label: '买卖方向',
          },
          {
            key: 'position_side',
            sortable: false,
            label: '持仓方向',
          },
          {
            key: 'type',
            sortable: false,
            label: '交易类型',
          },
          {
            key: 'price',
            sortable: false,
            label: '交易价格',
          },
          {
            key: 'qty',
            sortable: false,
            label: '交易数量',
          },
          {
            key: 'quote_qty',
            sortable: false,
            label: '成交额',
          },
          {
            key: 'commission',
            sortable: false,
            label: '手续费',
          },
          {
            key: 'commission_asset',
            sortable: false,
            label: '手续费单位',
          },
          {
            key: 'realized_pnl',
            sortable: false,
            label: '实现盈亏',
          },
          {
            key: 'time',
            sortable: true,
            label: '交易时间',
          },
        ],
        items: [],
        page: 1,
        pageSize: 10,
        total: 0,
      },
      tradeTypeTexts: {
        spot: '现货',
        future: 'U本位合约',
        delivery: '币本位合约',
      },
      tradeSideTexts: {
        BUY: '买入',
        SELL: '卖出',
      },
      tradePositionSideTexts: {
        LONG: '开多',
        SHORT: '开空',
        BOTH: '单向持仓',
      },
      tradeQuery: {
        symbol: '',
        side: '',
        type: '',
        position_side: '',
      },
    }
  },
  computed: {
    acId () {
      return this.$route.query.id
    },
    tradeFields () {
      const filterMap = {
        spot: /pair|margin_asset|realized_pnl|position_side/,
        future: /margin_asset|pair/,
        delivery: '',
      }
      return this.trade.fields.filter(f => {
        const reg = filterMap[this.tradeQuery.type]
        return !this.tradeQuery.type || !reg || !reg.test(f.key)
      })
    },
  },
  filters: {
    toFixed (val) {
      return toRoundFixed(val, 4)
    },
    toPercent,
    toDateFmt (val) {
      return dayjs(val).format('YYYY-MM-DD HH:mm:ss')
    },
  },
  watch: {
    tradeQuery: {
      deep: true,
      handler () {
        this.fetchTradeList()
      },
    },
  },
  created () {
    this.fetchSeriesData()
    if (this.acId) {
      this.fetchAssetAccountDetail()
    }
  },
  methods: {
    async fetchAssetAccountDetail () {
      await getAssetAccountDetail(this.acId).then(res => {
        this.assetAccountDetail = res.data
      })
      await getAssetAccountData(this.acId).then(res => {
        this.assetDataDetail = res.data
      })
      await getAssetAccountSymbol(this.acId).then(res => {
        this.symbols = res.data.results?.filter(s => s.symbol !== 'USDT') || []
      })
      await this.fetchTradeList()
    },
    fetchSeriesData () {
      const start_time = gMap[this.currentGranularity]?.[0]
      const end_time = gMap[this.currentGranularity]?.[1]
      return getAssetAccountSeriesData(this.acId, {
        start_time,
        end_time,
      }).then(res => {
        const data = (res.data?.series || []).map(s => ({
          name: s.time,
          value: toRoundFixed(s.net_value, 4),
        }))
        this.$refs.charts?.chart?.setOption({
          xAxis: {
            data: data.map(d => d.name),
          },
          series: [{ data }],
        })
      })
    },
    fetchTradeList () {
      return getAssetAccountTrade(this.acId, {
        page: this.trade.page,
        page_size: this.trade.pageSize,
        ...this.tradeQuery,
      }).then(res => {
        this.trade.items = res.data.results || []
        this.trade.total = res.data.count || 0
      })
    },
    toggleLegend (legend) {
      const { name } = legend
      if (this.$refs.charts?.chart) {
        this.$refs.charts.chart.dispatchAction({
          type: 'legendToggleSelect',
          // 图例名称
          name,
        })
        legend.hidden = !legend.hidden
      }
    },
    selectGranularity (g) {
      this.currentGranularity = g.value
      this.fetchSeriesData()
    },
    showContactDialog () {
      this.contactDialogVisible = true
    },
  },
}
</script>

<style lang="less" scoped>
@import '../assets/styles/variables';

.grade-detail {
  padding-top: 76px;
  font-size: 12px;
  background: #f9f9fc;
  min-height: 100vh;

  h2 {
    font-weight: 700;
  }
}

.grade-detail-container {
  margin-top: 50px;
}

.detail-board {
  margin-top: 30px;
  padding: 0.5rem;
  background: rgba(34, 37, 49, 0.05);
}

.detail-board-item {
  display: flex;
  justify-content: space-between;
  border: 0;
  background: transparent;

  &:not(:last-child) {
    border-bottom: 1px dashed #999;
  }

  .text {
    font-weight: 700;
  }

}

.total-item {

  h3 {
    font-weight: 700;
    font-size: 1rem;
    line-height: 20px;
  }
}

.year-return-ratio {
  color: @green;
}

.total-card {
  margin-top: 30px;

}

.chart-container {
  margin-top: 40px;
}

.charts-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed rgba(34, 37, 49, 0.5);
  padding: 10px 0;
}

.charts-granularity {
  display: flex;
}

.charts-legend {

}

.charts-granularity-item {
  font-size: 12px;
  color: rgba(34, 37, 49, 0.5);
  font-weight: bold;
  user-select: none;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:hover, &.active {
    color: #222531;
  }
}

.charts-legend-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;

  .legend-symbol {
    margin-right: 0.5em;
    display: inline-block;
    width: 1em;
    height: 2px;
  }

  &.hidden {
    .legend-symbol {
      background: #999 !important;
    }
  }
}

.trade-query-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  .b-dropdown {
    &:not(:last-child) {
      margin-right: 10px;
    }
    ::v-deep .btn {
      margin-top: 4px;
      font-size: 12px;
    }
  }
}
</style>
